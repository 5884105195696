import { Injectable } from "@angular/core";
import { GlobalService } from "./global.service";
import { DeviceService } from "./device.service";
import { environment } from "@root/environment";
import { Report } from "../class/Report";
import { Alert } from "../class/core/Alert";
import * as parse from "@interfaces/parseClasses";
import Parse from "parse";
import { AWSS3Service } from "./aws.service";
import _ from 'lodash';


/** Class to provide Parse services throughout the app. */
@Injectable({
  providedIn: "root",
})
export class ParseService {
  constructor(private global: GlobalService, private device: DeviceService) {
    this.initializeParse();
  }

  /** Initializes Parse server. */
  async initializeParse() {
    Parse.serverURL = environment.Parse.serverURL;
    Parse.initialize(environment.Parse.applicationId, environment.Parse.jsKey);
    Parse.CoreManager.set("REQUEST_ATTEMPT_LIMIT", 0);
    AWSS3Service.initialize(await this.getParseConfig("S3UserAccessKey"));
  }

  // @@@@@@@@@@@@@@@@@@@@ Parse CLOUD @@@@@@@@@@@@@@@@@@@@
  /** Called when user adds a new local to a station. This function will update the database.
   * @param station Station where the user added a new local.
   * @param message Name of the new local.
   * @returns Promise. */
  async addLocalTransfersCloud(station: parse.Station, message: string) {
    return await Parse.Cloud.run("newTransfersPlace", {
      user: (await this.getCurrentUser()).nome,
      station: station.name + " (" + station.zona.get("name") + ")",
      message: message,
      versionApp: await this.device.getAppVersion(),
    });
  }

  async uploadImageCloud(image: any, bucketName: string) {
    return await Parse.Cloud.run("uploadImage", {
      image: image,
      bucketName: bucketName,
    });
  }

  async removeImageCloud(objectKey: string, bucketName: string) {
    return await Parse.Cloud.run("removeImage", {
      bucketName: bucketName,
      objectKey: objectKey,
    });
  }

  // /** Called to add new Impro to Impro database.
  //  * @param impro Impro to add.
  //  * @param image Image to add.
  //  * @returns Promise. */
  // async createImgFromImpro(impro: parse.Impro, image: any) {
  //   const file = new Parse.Object("File");
  //   const Impro = new Parse.Object("Impro");
  //   Impro.id = impro.id;
  //   file.set("file", new Parse.File("image", image.file));
  //   file.set("impro", Impro);
  //   file.set("name", image.name);
  //   return await file.save();
  // }

  /** Called when user creates a new "Rendimentos" item. This function will update the database.
   * @param object Info to create new item.
   * @returns Promise. */
  async createRendimento(object: Parse.Object) {
    return await Parse.Cloud.run("createRendimento", {
      rend: JSON.stringify(object),
    });
  }

  // @@@@@@@@@@@@@@@@@@@@ Parse Users @@@@@@@@@@@@@@@@@@@@
  /** Retrieves info about user from database. */
  async getCurrentUser() {
    let user = Parse.User.current();
    if (user) {
      const query = new Parse.Query(Parse.User);
      query.include("zoneP");
      let u = await query.get(user.id);
      if (u)
        return {
          id: u.id,
          createdAt: u.createdAt,
          updatedAt: u.updatedAt,
          nome: u.get("nome"),
          username: u.get("username"),
          email: u.get("email"),
          typeUser: u.get("typeUser"),
          job: u.get("job"),
          password: u.get("password"),
          sessionToken: u.get("sessionToken"),
          createdBy: u.get("createdBy"),
          // Descontinuado
          // zone: u.get('zone'),
          zoneP: u.get("zoneP"),
          objetivoMensal: u.get("objetivoMensal") || 1000,
          valorHora: u.get("valorHora") || 3.75,
        };
    }
  }

  /** Updates user's monthly goal in database.
   * @param user User to update.
   * @param objetivoMensal New monthly goal.
   * @returns Promise. */
  async updateObjetivoMensal(user: parse.User, objetivoMensal: number) {
    const query = new Parse.Query(Parse.User);
    let u = await query.get(user.id);
    u.set("objetivoMensal", objetivoMensal);
    return await u.save();
  }

  /** Updates user's hourly wage in database.
   * @param user User to update.
   * @param valorHora New hourly wage.
   * @returns Promise. */
  async updateValorHora(user: parse.User, valorHora: number) {
    const query = new Parse.Query(Parse.User);
    let u = await query.get(user.id);
    u.set("valorHora", valorHora);
    return await u.save();
  }

  // @@@@@@@@@@@@@@@@@@@@ Parse Zones @@@@@@@@@@@@@@@@@@@@
  /** Retrieves info about zone from database.
   * @param id ID of the zone to retrieve.
   * @returns Zone info. */
  async getZone(id: string) {
    const query = await new Parse.Query(Parse.Object.extend("Zones"));
    query.equalTo("objectId", id);
    let zone = await query.find();
    return zone;
  }

  async getZones() {
    const query = await new Parse.Query(Parse.Object.extend("Zones"));
    const zones = await query.find();
    // await Parse.Object.pinAll(zones);
    return zones;
  }

  async getClients() {
    const query = await new Parse.Query(Parse.Object.extend("Client"));
    return await query.find();
  }

  async getStations(available: boolean, zones: string[], client?: any) {
    const query = await new Parse.Query(Parse.Object.extend("stations"));
    if (available) query.equalTo("available", available);
    query.notEqualTo("deleted", true);
    query.containedIn("zona", zones);
    if (client != undefined) query.equalTo("client", client);
    const results: any[] = await query.find();
    return results;
  }

  // @@@@@@@@@@@@@@@@@@@@ Parse Stations @@@@@@@@@@@@@@@@@@@@
  /** Adds a new place to a station in the database.
   * @param newPlace Name of the new place.
   * @param estacao Station to add the new place to.
   * @returns Promise. */
  public async newPlace(newPlace: string, estacao: parse.Station) {
    let pedding: string[] = estacao.pendingPlaces;
    pedding.push(newPlace);
    const station = await new Parse.Query(Parse.Object.extend("stations"));
    let s = await station.get(estacao.id);
    s.set("pendingPlaces", pedding);
    return await s.save();
  }

  async getPlaces(client, zones?: string[]) {
    const query = new Parse.Query(Parse.Object.extend("stations"));
    if (zones) query.containedIn("zona", zones);
    query.notEqualTo("deleted", true);
    query.equalTo("client", new Parse.Object("Client", { id: client.id }));
    return await query.find();
  }

  /** Retrieves information about a station.
   * @param uid ID of the station to retrieve information from.
   * @returns Station information. */
  async getStation(uid) {
    const query = await new Parse.Query(Parse.Object.extend("stations"));
    query.equalTo("objectId", uid);
    const result: parse.Station = (await query.first()) as any;
    if (result) {
      return {
        className: result.className,
        id: result.id,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
        name: result.get("name"),
        places: result.get("places"),
        zona: result.get("zona"),
        client: result.get("client"),
      };
    }
  }

  // @@@@@@@@@@@@@@@@@@@@ Parse Config @@@@@@@@@@@@@@@@@@@@
  /** Retrieves information from the parse config.
   * @param key Keyword to find the information to retrieve.
   * @returns Information from the parse config. */
  async getParseConfig(key: string) {
    let c = await Parse.Config.get();
    return await c.get(key);
  }

  // @@@@@@@@@@@@@@@@@@@@ Parse Auth @@@@@@@@@@@@@@@@@@@@
  /** Authenticates the user based on the information on database.
   * @param username Username of the user.
   * @param password Password of the user.
   * @returns Promise. */
  async login(username: string, password: string) {
    const user = await Parse.User.logIn(username, password);
    if (user)
      return {
        id: user.id,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
        nome: user.get("nome"),
        username: user.get("username"),
        email: user.get("email"),
        typeUser: user.get("typeUser"),
        job: user.get("job"),
        password: user.get("password"),
        sessionToken: user.get("sessionToken"),
        createdBy: user.get("createdBy"),
        // zone: user.get('zone'),
        zoneP: user.get("zoneP"),
        objetivoMensal: user.get("objetivoMensal"),
      };
    return await user.save();
  }

  /** Logout function will logout the user and clear the session.
   * @param ask - If true, will ask the user if he really wants to logout. */
  async logout(ask: boolean) {
    if (ask) {
      Alert.confirmAlert("global.confirmAlert.willBeDisconnected").then((res) => {
        if (res) {
          Parse.User.logOut()
            .then(() => {
              this.global.navToRoot("/login");
              this.global.cleanStorage();
            })
            .catch((error) => new Report(error, "ParseService.logout.1"));
        }
      });
    } else {
      Parse.User.logOut()
        .then(() => {
          this.global.navToRoot("/login");
          this.global.cleanStorage();
        })
        .catch((error) => new Report(error, "ParseService.logout.2"));
    }
  }

  /** Sends a password reset email to the user.
   * @param email - Email of the user.
   * @returns Promise. */
  async resetPassword(email) {
    // Pass the username and password to logIn function
    return await Parse.User.requestPasswordReset(email);
  }

  // @@@@@@@@@@@@@@@@@@@@ RENDIMENTOS @@@@@@@@@@@@@@@@@@@@
  /** Retrieves "Rendimentos" items from database.
   * @param dateInit - Initial date to retrieve items from.
   * @param dateEnd - Final date to retrieve items from.
   * @returns Array with list of "Rendimento" items. */
  async getRendimentos(dateInit: Date, dateEnd: Date): Promise<Parse.Object[]> {
    const query = await new Parse.Query(Parse.Object.extend("Rendimentos"));
    query.greaterThan("jobStart", dateInit);
    query.lessThan("jobEnd", dateEnd);
    query.equalTo("user", await Parse.User.current());
    query.notEqualTo("deleted", true);
    query.descending("jobStart");
    return (await query.find()) || [];
  }
  /** Removes a "Rendimento" item from database.
   * @param rend - Rendimento item to be removed.
   * @returns Promise. */
  async removeRendimentos(rend: Parse.Object) {
    const cars = await new Parse.Query(Parse.Object.extend(rend.className));
    let car = await cars.get(rend.id);
    car.set("deleted", true);
    return await car.save();
  }
}




export async function parseObjectToObject<T>(parseObj: any, object: any): Promise<T> {
  if (object == undefined) return;
  const parse = { ...parseObj, ...parseObj?.attributes };
  for await (let prop of Object.getOwnPropertyNames(object)) {
    try {
      if (parse[prop] == undefined || prop == "className") continue;
      if (typeof parse[prop] == "object" && parse[prop]?.id != undefined && parse[prop]?.className != undefined)
        object[prop] = _.cloneDeep(await parseObjectToObject(parse[prop], _.cloneDeep(object[prop])));
      else object[prop] = _.cloneDeep(parse[prop]);
    } catch (e) {
      if (parse[prop] != undefined) object[prop] = parse[prop];
      let value = "null";
      try {
        value = JSON.stringify(parse[prop]);
      } catch (__) {
        value = parse[prop]?.toString() || "null";
      }
      Report.sendLog(`prop: ${prop} value: ${value} msg: ${e}`, "parseObjectToObject");
    }
  }
  return object;
}
