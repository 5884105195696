import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ParseService } from "./services/parse.service";
import { Alert } from "./class/core/Alert";
import Parse from "parse";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(private parse: ParseService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (Parse.User.current()) {
      return true;
    } else {
      Alert.simpleToast("global.toast.invalidSession");
      this.parse.logout(false);
      return false;
    }
  }
}
