import { Component } from "@angular/core";
import { MenuController, Platform } from "@ionic/angular";
import { ParseService } from "./services/parse.service";
import { GlobalService } from "./services/global.service";
import { DeviceService } from "./services/device.service";
import { Station, User } from "../../../interfaces/parseClasses";
import { Config } from "@interfaces/config";
import { environment } from "@root/environment";
import { TranslateService } from "@ngx-translate/core";
import { SwUpdate } from "@angular/service-worker";
import { Report } from "./class/Report";
import { Alert } from "./class/core/Alert";
import { register } from "swiper/element/bundle";

/** App component. */
register();
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public installButtonPwaAndroid;
  /** If true, app is in production mode. False otherwise. */
  public isProd: boolean = false;
  /** Version of the app currently installed. */
  public versionApp: string;
  /** Contains information from Config. */
  public Config: Config = {
    station: undefined,
    telSupervisor: undefined,
    parseAppVersionUpdate: undefined,
    // movs: [],
    abs: [],
  };
  /** Contains information from User. */
  public static User: User = {
    email: "",
    nome: "",
    username: "",
    typeUser: undefined,
    password: "",
    sessionToken: "",
    createdBy: undefined,
    zoneP: undefined,
    objetivoMensal: undefined,
    valorHora: undefined,
  };
  /** Array containing a list of stations. */
  private Stations: Station[];
  /** @bug not used. */
  public appPages = [];

  constructor(
    private platform: Platform,
    public parse: ParseService,
    public global: GlobalService,
    public menu: MenuController,
    public device: DeviceService,
    private translate: TranslateService,
    private swUpdate: SwUpdate
  ) {
    this.initializeApp();
    this.initTranslate();
  }

  /** Called when initializing the app. */
  private initializeApp() {
    this.platform.ready().then(async () => {
      let isDev = !environment.production && !environment.staging;
      // If the app is running as a PWA app
      // Verify updates
      if (this.swUpdate.versionUpdates) {
        this.swUpdate.versionUpdates.subscribe(async (event) => {
          if (event.type == "VERSION_READY") {
            Alert.simpleToast("global.toast.initializeApp");
            document.location.reload();
          }
        });
      }
      // Prevents from installing the app again
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.installButtonPwaAndroid = e;
      });
      // If the app is running as a PWA, initialize app
      if (window.matchMedia("(display-mode: standalone)").matches) {
        let currentUser: User = await this.parse.getCurrentUser();
        // If the user is logged in
        if (currentUser) {
          await this.initializeVariables(currentUser);
        } else {
          this.parse.logout(false);
        }
        this.isProd = environment.production;
        this.menu.enable(true);
        // If we're viewing the app in a browser (NOT PWA)
      } else if (!isDev) {
        this.global.navToRoot("redirect");
        this.menu.enable(false);
      } else {
        // If we're on dev mode
        let currentUser: User = await this.parse.getCurrentUser();
        // If the user is logged in
        if (currentUser) {
          await this.initializeVariables(currentUser);
        } else {
          this.parse.logout(false);
        }
      }
    });
  }

  /** Init ngx-translate to minimize height alerts. */
  private async initTranslate() {
    this.translate.setDefaultLang(environment.defaultLanguage);
  }

  /** Loads information related to user.
   * @param currentUser Information about user currently logged in. */
  async initializeVariables(currentUser: User) {
    this.menu.enable(true);
    AppComponent.User = currentUser;
    this.Config.parseAppVersionUpdate = await this.parse.getParseConfig("version");
    this.versionApp = await this.device.getAppVersion();
    this.Stations = await this.parse.getStations(true, [...[], this.getZone.id]);
    this.versionControl();
  }

  /** Checks if the app version currently in use is still supported. */
  private async versionControl() {
    const versionParse = await this.parse.getParseConfig("major_version");
    if (parseFloat(this.versionApp) < versionParse.carroface) {
      new Report({ message: "Deprecated Version" }, "AC.versionControl");
    }
    this.global.navToRoot("home");
  }

  /** Syncronizes all data from the app. */
  async sync() {
    await this.global.simpleLoading();
    this.menu.close();
    AppComponent.User = await this.parse.getCurrentUser();
    this.Config.parseAppVersionUpdate = await this.parse.getParseConfig("version");
    this.versionApp = await this.device.getAppVersion();
    this.Stations = await this.parse.getStations(true, [...[], this.getZone.id]);
    if (this.Config.station) {
      this.Stations.forEach((item) => {
        if (item.id == this.Config.station.id) this.Config.station = item;
      });
    }
    Alert.simpleToast("global.toast.sync");
    await this.global.cancelLoad();
  }

  /** Retrieves information on the current user's zone.
   * @returns Information on the current user's zone. */
  get getZone() {
    return AppComponent.User.zoneP;
  }

  /** Retrieves an array containing a list of stations.
   * @returns Array containing a list of stations. */
  get getStations() {
    return this.Stations;
  }

  /** Retrieves information on a specific station.
   * @returns Information on a specific station. */
  get getStation() {
    return this.Config.station;
  }

  /** Retrieves information on the current user.
   * @returns Information on the current user. */
  get getUser(): User {
    return AppComponent.User;
  }

  /** @bug not used -> check origin-destiny.component.html
   * @returns  */
  get getPlaces() {
    return this.Stations;
  }

  /** Retrieves an array contaning a list of movs.
   * @returns Array contaning a list of movs. */
  // get getMovs() {
  //   // return this.Config.movs;
  // }

  /** Retrieves an array contaning a list of abs.
   * @returns Array contaning a list of abs. */
  get getAbs() {
    return this.Config.abs;
  }

  /** Retrieves information about the last successfull mov.
   * @returns Information about the last successfull mov. */
  // get getMovsLastMov() {
  //   return this.Config.movs[0];
  // }

  /** Checks if the station exists.
   * @returns True if the station exists, false otherwise. */
  get stationIsValid() {
    return this.Config.station ? true : false;
  }
}
