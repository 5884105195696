import Parse from "parse";
import { environment } from "@root/environment";
import { Alert } from "./core/Alert";

/**
 * Class to validate and report to parse Logs class.
 * The user will be notified of the validated error.
 * @extends Alert
 */
export class Report {
  constructor(
    public errorMessage: { message: string },
    public route: string,
    isAlert: boolean = true
  ) {
    const msgValidated = this.erroValidators(this.errorMessage, this.route);
    if (isAlert) Alert.simpleAlert("global.simpleAlert.report", msgValidated);
    // this.freeIdempotency();
  }

  /**
   * Sends an error log to the server.
   * @param error - The error that was thrown.
   * @param route - The route where the error occurred.
   */
  public static sendLog(error: any, route: string) {
    const userUID = Parse.User.current()?.id;
    const url = environment.Parse.serverURL + "/classes/Log";
    let err = JSON.stringify(error);
    if (err == "{}") err = error.toString();
    const body = {
      error: err,
      route: route,
      appName: environment.APP_NAME,
      version: environment.VERSION,
      user: undefined,
    };
    if (userUID) body.user = { __type: "Pointer", className: "_User", objectId: userUID };

    fetch(url, {
      method: "POST", // or 'PUT'
      headers: {
        "X-Parse-Application-Id": environment.Parse.applicationId,
        "X-Parse-REST-API-Key": environment.Parse.rest,
        "content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  /**
   * It takes an error and the route where the error was generated, and returns a string.
   * @param error - The error message that was thrown.
   * @param route - The route where the error occurred.
   * @returns The error message validated.
   */
  private erroValidators(error: { message: string }, route: string): string {
    if (!environment.production) console.log(error);
    switch (error?.message) {
      case `XMLHttpRequest failed: "Unable to connect to the Parse API"`:
        return "Sem conexão com a internet";
      case "Duplicate request":
        return "Informação já registrada";
      case "Permission denied, user needs to be authenticated.":
      case "Invalid session token":
        return `Sem autenticação, faça login novamente`;
      case "Deprecated Version":
        Report.sendLog(error, route);
        return `Você está usando uma versão desatualizada do aplicativo,
        atualize-o fechando e abrindo a app para garantir um bom funcionamento.`;
      default:
        Report.sendLog(error, route);
        return error?.message;
    }
  }

  /** Clean X-Parse-Request-Id to prevent problems, because if
   * another request not contains REQUEST_HEADERS it will return
   * duplicate request error.
   * @deprecated 3.7.1 - It's commented in line 14 so it's not doing anything. I'll comment it for now and wait for approval. */
  private freeIdempotency() {
    // Parse.CoreManager.set("REQUEST_HEADERS", {
    //   "X-Parse-Request-Id": "",
    // });
  }

  /** Checks the error message to see if it contains the string "Not implemented on web".
   * If it does, then the error will be ignored and not sent to the server.
   * @param error The error message that was thrown.
   * @returns True if the error message contains the string "Not implemented on web". */
  static ignoreError(error: string) {
    return error.includes("Not implemented on web");
  }
}
