export const environment: Environment = {
  production: true,
  staging: false,
  defaultLanguage: "pt",
  APP_NAME: require("package.json").name,
  VERSION: require("../package.json").version,
  appUrl: "https://logiface.herokuapp.com/",
  checkinBucket: "starface-check-in-test",
  transferBucket: "starface-transfers-test", // starface-transfers
  Parse: {
    serverURL: "https://server.starface.orientech.io/parse",
    applicationId: "APP_ID",
    jsKey: "JS_KEY",
    masterKey: "NeverShareYourMasterKey",
    rest: "REST_KEY",
  },
  clientCarface: "m2cnl4GmOO"
};

export interface Environment {
  production: boolean;
  staging: boolean;
  defaultLanguage: "pt";
  APP_NAME: string;
  VERSION: string;
  appUrl: string;
  checkinBucket: string;
  transferBucket: string;
  Parse: Parse;
  clientCarface: string
}

/** Interface to be included in Environment.
 *
 * @member {string} serverURL is the URL of the Parse server.
 * @member {string} applicationId is the application ID of the Parse server.
 * @member {string} jsKey is the JS key of the Parse server.
 * @member {string} masterKey is the master key of the Parse server.
 * @member {string} rest is the rest key of the Parse server. */
interface Parse {
  serverURL: string;
  applicationId: string;
  jsKey: string;
  masterKey: string;
  rest: string;
}
